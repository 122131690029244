<template>
  <Modal ref="modal" class="default profile-review" :settings="settings" :lock="$loading" @close="closeProfileReview">
    <div class="modal-default-title" slot="header">
      <p class="profile-review-title">{{ $locale["content_review"] }}</p>
    </div>
    <div class="profile-review-body">
      <div class="content-text">{{ $locale["content_review_info"] }}</div>
      <div class="content-list">
        <table class="table" width="100%">
          <tr class="tr" v-html="`<th>${$locale['content']}</th><th>${$locale['status']}</th>`"></tr>
          <tr class="tr" v-for="file in files" :key="file.Location">
            <td class="td image">
              <div :class="`media-file ${file.for}`" :style="`--img: url('${file.Location}');`">
                <!-- <img alt="Image" :src="`${file.Location}`" /> -->
              </div>
            </td>
            <td class="td ttop">
              <div class="file-info">
                <div class="status" :class="file.adminStatus">{{ $locale[file.adminStatus] }}</div>
                <div class="item date">
                  <div class="label">{{ $locale["uploaded"] }}</div>
                  <div class="value">{{ date_locale(file.date) }}</div>
                </div>
                <div class="item for">
                  <div class="label">{{ $locale["file_for"] }}</div>
                  <div class="value">{{ $locale[file.for] }}</div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  data: function() {
    return {
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
    };
  },
  methods: {
    closeProfileReview: function() {
      this.$store.state.user.profileReview = false;
      this.setQuery({ profile: undefined });
    },
  },
  computed: {
    files: function() {
      const files = [];

      if (this.$profile?.user?.payload?.picture) {
        files.push({ ...this.$profile.user.payload.picture, for: "avatar" });
      }

      if (this.$profile?.user?.payload?.cover) {
        files.push({ ...this.$profile.user.payload.cover, for: "cover" });
      }

      return files;
    },
  },
};
</script>

<style lang="scss">
.profile-review {
  display: block;
  &-title {
    padding: 0 $mpadding;
    font-weight: bold;
  }
  &-body {
    display: flex;
    flex-direction: column;
    padding: $mpadding;
  }
  .content-list {
    margin: $mpadding * 2 0 0 0;
    border-radius: $mpadding;
    overflow: hidden;
    border: solid 1px $alto;
  }
  .content-list .table {
    border-collapse: collapse;
    tr th,
    tr td {
      padding: $mpadding/1.5;
      text-align: left;
      max-width: 50%;
      width: 50%;
      position: relative;
      &.image {
        padding: 0;
        max-width: 70%;
        width: 60%;
        text-align: center;
      }
    }
    tr th {
      color: #bd0909;
      background-color: #bd09090f;
    }
    tr td {
      border-top: solid 1px $alto;
    }
    img {
      display: block;
      max-width: 100%;
      @include dshadow;
    }
    .ttop {
      vertical-align: text-top;
    }
  }
  .file-info {
    .status {
      font-weight: bold;
      border: solid 1px;
      display: inline-flex;
      padding: $mpadding/4 $mpadding/2;
      border-left: solid 3px;
      margin: 0 0 $mpadding 0;
      &.pending {
        color: #bd6909;
      }
      &.reject,
      &.rejected {
        color: #ff0000;
      }
      &.accept {
        color: #01b601;
      }
    }
    .item {
      padding: $mpadding/2 0 0 0;
    }
    .item .label {
      font-size: 80%;
      opacity: 0.8;
    }
    .item .value {
      padding: $mpadding/4 0 $mpadding/4 0;
    }
  }

  .media-file {
    height: 256px;
    background-image: var(--img);
    background-size: cover;
    background-position: center;
    &.avatar {
      width: 256px;
      border-radius: 50%;
      margin: $mpadding;
      display: inline-flex;
      align-self: center;
    }
  }

  @include screen_desktop() {
    &-body {
      padding: $mpadding * 2;
    }
  }
}
</style>
